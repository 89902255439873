import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Events">
            <Row>
                <Col>
                    <Link to="/">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/events/coffee-coworking" title="Coffee & Coworking" bg="pink" />
                <LinkCard to="/events/business-lunch" title="Business Lunch" bg="pink" />
                <LinkCard to="/events/tribe-night" title="Tribe Night" bg="pink" />
                <LinkCard to="/events/power-hour" title="Power Hour &amp; Summit" bg="pink" />
                <LinkCard to="/events/standard-graphics" title="Standard Graphics"  />
                <LinkCard to="/events/templates" title="Templates"  />
                <LinkCard to="/events/press-releases" title="Press Releases"  />



            </Row>
        </Layout>
    )
}

export default IndexPage
